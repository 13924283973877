<!--
 * @Author       : Hugo
 * @Date         : 2020-05-25 11:19:50
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-19 23:32:59
 * @Description  : 左边栏搜索条
 * @FilePath     : /miaohang/src/components/sidebar/searchGroup.vue
-->
<template>
  <div
    class="sidebar_search_group"
  >
    <div
      class="search_group"
    >
      <img
        src="@/assets/images/common/search_icon.png"
        alt=""
        class="icon"
      >
      <el-input
        type="text"
        v-model="input"
        placeholder="搜索"
        class="search"
        ref="input"
        @keydown.enter.native="search($event)"
      ></el-input>
      <div
        v-show="input != ''"
        class="clear cp"
        @click="input = '';$refs.input.focus();clearSearch();"
      ></div>
    </div>
    <div
      class="fun_group cp un_sel"
      @click.stop="plusSignHandler"
    >
      <img
        src="@/assets/images/common/add.png"
        alt=""
        class="icon"
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      input:''
    }
  },
  computed: {
    search_chat_groups () {
      return this.$store.state.common.search_chat_groups;
    },
    search_friends () {
      return this.$store.state.common.search_friends;
    },
  },
  watch: {
    input: function(val){
      if (val.indexOf(' ') !== -1) {
        this.input = val.replace(' ', '')

      }
      if (val === '') {
        this.clearSearch()
      }
      this.$store.commit('set_search_keyword', this.input)
    },
    search_chat_groups: {
      handler: function(){},
      deep: true
    },
    search_friends: {
      handler: function(){},
      deep: true
    },
  },
  methods: {
    /**
     * 添加好友(打开弹窗)
     */
    add_friend () {
      // console.log('add')
      this.$api.common_open_add_friend_box()
      // this.$store.commit('open_add_friend_box', true);
    },
    /**
     * 加号钩子
     */
    plusSignHandler () {
      let routename = this.$route.name
      if (routename.indexOf('fun_task') !== -1) {
        this.$api.common_set_add_contact_to_dialog_box()
        this.$store.commit(
          'set_add_contact_dialog_box_type',
          'create'
        )
      } else if (routename.indexOf('fun_contact') !== -1) {
        // 联系人频道
        this.add_friend()
      }
    },
    chineseHandler (e) {
      let status = false
      if (Number(e.keyCode) === 229) {
        status = true
      }
      return status
    },
    async search (e) {
      // 中文输入法回车
      const is_chinese = this.chineseHandler(e)
      if (is_chinese) {
        return false
      }
      if (this.input) {
        await this.$store.dispatch(
          'common_get_search_data',
          { key: this.input }
        )
      }
    },
    async getTask () {
      const { input } = this
      const res = await this.$global.doPost({
        url: '/project/searchTask',
        data: {param: input},
        notice: '搜索任务失败',
      })
      if (res.message === 'success') {
        this.$store.commit('set_search_task', res.contents.tasks)
      }
    },
    /** 清除搜索 */
    clearSearch () {
      this.$store.commit('set_search_keyword', '')
      this.$store.dispatch('common_clear_search')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar_search_group {
  @include flexsb;
  @include bbox;
  border-bottom: 2px solid $border_color;
  background-color:#fff;
  padding: 16px;
  width: 100%;
  .search_group{
    @include flex;
    @include bbox;
    // width:170px;
    width: 100%;
    flex: 1;
    margin-right: 10px;
    height:30px;
    background:rgba(243,243,243,1);
    border-radius:15px;
    padding: 0 16px;
    position: relative;
    .icon{
      flex-shrink: 0;
      width: 14px;
      height: 14px;
    }
    .search{
      @include bbox;
      flex: 1;
      width: 100%;
      padding-right: 25px;
    }
    .clear{
      @include background(14px, 14px);
      position: absolute;
      width: 20px;
      height: 20px;
      top: 50%;
      margin-top: -10px;
      right: 3px;
      background-image: url(~@/assets/images/common/clear_icon.png)
    }
  }
  .fun_group{
    @include bbox;
    @include flex;
    justify-content: center;
    width:30px;
    height:30px;
    background:rgba(243,243,243,1);
    border-radius:50%;
    .icon{
      width:10px;
      height: 11px;
    }
  }
}
</style>
<style>
  .sidebar_search_group .search .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;

    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>
