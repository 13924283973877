var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sidebar_search_group" }, [
    _c(
      "div",
      { staticClass: "search_group" },
      [
        _c("img", {
          staticClass: "icon",
          attrs: {
            src: require("@/assets/images/common/search_icon.png"),
            alt: "",
          },
        }),
        _c("el-input", {
          ref: "input",
          staticClass: "search",
          attrs: { type: "text", placeholder: "搜索" },
          nativeOn: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.search($event)
            },
          },
          model: {
            value: _vm.input,
            callback: function ($$v) {
              _vm.input = $$v
            },
            expression: "input",
          },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.input != "",
              expression: "input != ''",
            },
          ],
          staticClass: "clear cp",
          on: {
            click: function ($event) {
              _vm.input = ""
              _vm.$refs.input.focus()
              _vm.clearSearch()
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "fun_group cp un_sel",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.plusSignHandler.apply(null, arguments)
          },
        },
      },
      [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/images/common/add.png"), alt: "" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }